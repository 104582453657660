<template>
  <div class="notice">
    <bict-header/>
    <div class="bict-banner">
      <div class="bict-banner-bg">
        <img src="../assets/icon/icon_banner_notice.png" alt="" class="image">
      </div>
      <div class="bict-banner-box">
        <div class="bict-title">
          <h3 class="bict-h3">{{ $t('Announcement Centre') }}</h3>
          <h3 class="bict-sub">{{$t('BICT Latest News')}}</h3>
        </div>
      </div>
    </div>
    <div class="bict-notice">
      <div class="bict-notice-left">
        <ul class="bict-ul">
          <li class="bict-li active">
            <span class="text">{{ $t('Latest Announcements') }}</span>
          </li>
          <li class="bict-li ">
            <span class="text">{{ $t('Launched new coins') }}</span>
          </li>
          <li class="bict-li ">
            <span class="text">{{$t('All announcements')}}</span>
          </li>
        </ul>
      </div>
      <div class="bict-notice-right">
        <div class="bict-notice-title">
          <h3 class="bict-h3">{{ $t('Latest Announcements') }}</h3>
        </div>
        <div class="bict-notice-list">
          <div class="bict-notice-box" v-loading="articleLoad">
            <ul class="bict-ul">
              <li class="bict-li" v-for="(item,index) in articleList" :key="index" @click="handleNotice(item)">
                <div class="bict-notice-item">
                  <img src="../assets/icon/icon_collect.png" alt="" class="icon">
                  <p class="text">{{ item.title }}</p>
                </div>
                <div class="bict-notice-date">
                  <p class="text">2022-03-03</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="bict-pagination">
            <el-pagination
                :hide-on-single-page="true"
                layout="prev, pager, next"
                :total="pagination.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <bict-bottom/>
  </div>
</template>

<script>
import BictHeader from "../components/BictHeader";
import BictBottom from "../components/BictBottom";
import { ProcoinArticle } from "../api";

export default {
  name: "index",
  components: {
    BictHeader,
    BictBottom
  },
  data () {
    return {
      articleList: [],
      params: {
        pageNo: 1,
        pageSize: 10,
        lang: 'tw'
      },
      articleLoad: true,
      pagination: {
        total: 5
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.articleLoad = true
      ProcoinArticle(this.params).then(res => {
        if (res.code == 200) {
          this.articleList = res.data.data
          this.pagination.total = parseInt(res.data.total)
          this.articleLoad = false
        }
      })
    },
    handleNotice (event) {
      this.$router.push({
        path: '/notice/content/' + event.articleId,
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .notice {
    .bict-notice {
      width: 1200px;
    }
  }
}

@media screen and (max-width: 768px) {
  .notice {
    .bict-about-image {
      display: none;
    }

    .bict-banner {
      overflow: hidden;

      .bict-banner-bg {
        img {
          width: auto;
          height: 300px;
        }
      }
    }

    .bict-notice {
      flex-direction: column;
      padding: 0 20px !important;
      margin-top: 20px !important;

      .bict-notice-left {
        width: 100% !important;

        .bict-ul {
          display: flex;
          align-items: center;
        }
      }

      .bict-notice-right {
        margin-left: 0 !important;
      }
    }
  }

}

.notice {
  background: #ffffff;

  .bict-banner {
    width: 100%;
    margin-top: 50px;
    position: relative;

    .image {
      width: 100%;
    }

    .bict-banner-box {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 28%;

      .bict-title {
        text-align: center;

        .bict-h3 {
          font-size: 39px;
          font-family: FZHei-B01T;
          font-weight: bold;
          color: #FFFFFF;
        }

        .bict-sub {
          margin-top: 20px;
          font-size: 18px;
          font-family: FZHei-B01T;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }

  .bict-notice {
    padding: 0 80px;
    margin: 50px auto auto;
    position: relative;
    overflow: hidden;
    display: flex;

    .bict-notice-left {
      width: 200px;
      margin-top: 20px;

      .bict-ul {
        .bict-li {
          list-style: none;
          padding-left: 22px;
          cursor: pointer;
          position: relative;
          margin-bottom: 27px;

          .text {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }

          &.active {
            &:before {
              position: absolute;
              left: 0;
              content: '';
              width: 2px;
              top: 4px;
              bottom: 4px;

              height: 10px;
              background: #006FFF;

            }

            .text {
              color: #006FFF;
            }
          }
        }
      }
    }

    .bict-notice-right {
      flex: 1;
      margin-left: 260px;

      .bict-notice-title {
        .bict-h3 {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #1C242C;
        }
      }

      .bict-notice-list {
        margin-top: 20px;
        width: 100%;

        .bict-notice-box {
          min-height: 400px;

          .bict-ul {
            .bict-li {
              list-style: none;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 20px 0;
              border-bottom: 1px solid #ECF1F8;
              cursor: pointer;

              &:last-child {
                border: 0;
              }

              .bict-notice-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex: 1;

                .icon {
                  width: 16px;
                  height: 15px;
                }

                .text {
                  margin-left: 10px;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #0B0B0B;
                }
              }

              .bict-notice-date {
                height: 100%;

                .text {
                  font-size: 12px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #999999;
                }
              }
            }
          }
        }

        .bict-pagination {
          margin-top: 60px;
          text-align: right;
        }
      }
    }
  }

}
</style>
